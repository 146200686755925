<template>
  <div>
    <section class="bg-grey-4" v-if="isAdmin">
      <div class="row q-mx-auto justify-start items-center">
        <div class="col q-pa-md">
          <q-btn
            icon="add"
            label="Add Viewpoint Collection"
            @click="addViewpoint"
          />
        </div>
      </div>
    </section>

    <q-dialog
      v-model="toggleForm"
      persisent
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <viewpoint-collection-form
        @reload="reload"
        :record="current"
        :action="action"
      />
    </q-dialog>

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <span :key="vp.uuid" v-for="vp in items" :id="vp.uuid">
            <card
              :title="vp.title"
              :image="vp.icon"
              :url="vp.url"
              type="Viewpoint Collection"
              :csstype="vp.is_visible ? 'ViewpointCollection' : 'private'"
              :btn-edit-modal="user === vp.owner"
              @showEdit="showEdit(vp)"
              :btn-delete="user === vp.owner"
              @deleteCard="remove(vp.uuid)"
              :btn-publish="!vp.is_visible"
              :btn-un-publish="vp.is_visible"
              @publish="publish(vp)"
              @unpublish="publish(vp)"
              >{{ vp.introduction }}
            </card>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { API } from '@/tic';
import ViewpointCollectionForm from '@/forms/ViewpointCollection';
import Card from '../tags/Card';

export default {
  name: 'ViewpointsIndex',
  components: { ViewpointCollectionForm, Card },
  data() {
    return {
      items: [],
      current: null,
      action: 'Add',
      toggleForm: false
    };
  },
  mounted() {
    this.reload();
  },

  methods: {

    remove(uuid) {
      let vm = this;
      console.log("xx: ", uuid)
      API.post('/viewpoints/delete/collection', {
        collection: uuid,
        user: vm.$store.getters['user/uuid']
      }).then(() => {
        vm.reload();
      });
    },

    publish(vp) {
      let vm = this;
      API.post('/viewpoints/toggle/visibility', {
        collection: vp.uuid,
        session: vm.$store.getters['user/session']
      }).then(() => {
        vm.reload();
      });
    },

    showEdit(e) {
      this.current = e;
      this.toggleForm = true;
      this.action = 'Edit';
    },

    addViewpoint() {
      this.current = {};
      this.action = 'Add';
      this.toggleForm = true;
    },

    reload(toggle = false) {
      //this.$q.notify({ message: 'RELOADED', position: 'center' });
      return API.get('/viewpoints/')
        .then(r => {
          this.items = [...r.data.data];
        })
        .then(() => {
          this.toggleForm = toggle;
        });
    }
  },

  computed: {
    ...mapGetters('user', { isAdmin: 'isAdmin', user: 'uuid' })
  }
};
</script>
